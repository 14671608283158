<template>
    <div>
        <q-layout class="bg-blue-grey-9">
            <div class="absolute-center text-center">
                <div class="text-h1 text-grey text-weight-bolder">Oops!</div>
                <div class="text-h4 text-grey text-weight-bold">Page Not Found</div>
                <div class=" text-grey text-weight-thin">The page you are looking for might have been removed</div>
                <q-btn rounded class="text-white q-mt-md" color="negative" to="./">go to home</q-btn>
            </div>
        </q-layout>
    </div>
</template>